// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail .detail-top {
  text-align: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

.detail .detail-top .detail-type {
  font-size: 18px;
  line-height: 30px;
}

.detail .detail-top .detail-amount {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}

.detail .detail-content {
  margin-bottom: 20px;
}

.detail .detail-content .cell-row {
  display: flex;
  justify-content: space-between;
}

.detail .detail-content .cell-row span:nth-child(1) {
  font-size: 16px;
  color: rgb(83, 84, 121);
  line-height: 30px;
}

.detail .detail-content .cell-row span:nth-child(2) {
  font-size: 16px;
  color: rgb(14, 11, 61);
  line-height: 30px;
  font-weight: 500;
}

.detail .btn-wrap {
  text-align: right;
  margin-top: 20px;
}

.detail .subTitle {
  margin: 0;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Wallet/components/detail.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,eAAe;AACjB","sourcesContent":[".detail .detail-top {\n  text-align: center;\n  border-bottom: 1px solid #ccc;\n  margin-bottom: 20px;\n}\n\n.detail .detail-top .detail-type {\n  font-size: 18px;\n  line-height: 30px;\n}\n\n.detail .detail-top .detail-amount {\n  font-size: 22px;\n  font-weight: 700;\n  line-height: 30px;\n  margin-bottom: 10px;\n}\n\n.detail .detail-content {\n  margin-bottom: 20px;\n}\n\n.detail .detail-content .cell-row {\n  display: flex;\n  justify-content: space-between;\n}\n\n.detail .detail-content .cell-row span:nth-child(1) {\n  font-size: 16px;\n  color: rgb(83, 84, 121);\n  line-height: 30px;\n}\n\n.detail .detail-content .cell-row span:nth-child(2) {\n  font-size: 16px;\n  color: rgb(14, 11, 61);\n  line-height: 30px;\n  font-weight: 500;\n}\n\n.detail .btn-wrap {\n  text-align: right;\n  margin-top: 20px;\n}\n\n.detail .subTitle {\n  margin: 0;\n  font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
