import React from "react";
import router from "./router";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config, projectId } from "./config/wagmi";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { LoadingProvider } from "./context/LoadingContext";

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  termsConditionsUrl: process.env.STAKE_VAULT_TERM,
  privacyPolicyUrl: process.env.STAKE_VAULT_POLICY,
  themeMode: "light",
  enableWalletFeatures: true,
  themeVariables: { "--w3m-z-index": 9999 },
  enableAnalytics: false,
  enableOnramp: false,
});

const App = () => {
  const queryClient = new QueryClient();

  return (
    <React.StrictMode>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <LoadingProvider>
            {router} {/* Render the router */}
          </LoadingProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </React.StrictMode>
  );
};

export default App;
