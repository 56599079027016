import { CopyrightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import XContact from "./components/XContact";
import TeleContact from "./components/TeleContact";
import { useTranslation } from "react-i18next";
import ContactModal from "./components/Contact";

const BaseFooter = () => {
    const { t } = useTranslation();
    const [isOpenContact, setOpenContact] = useState<boolean>(false);
    
    return (
        <footer className="footer">
            <div className="left-footer">
                <div className="copyright">
                    <CopyrightOutlined />
                    <div className="link-whitepaper">2024</div>
                </div>
                <div className="logo-footer">STAKING VAULT</div>
                <div className="term"><a href={process.env.STAKE_VAULT_TERM} target="_blank" rel="noreferrer">{t("termsOfService")}</a></div>
                <div className="policy"><a href={process.env.STAKE_VAULT_POLICY} target="_blank" rel="noreferrer">{t("privacyPolicy")}</a></div>
                <button onClick={() => setOpenContact(true)} className="contact-button">{t("contact")}</button>
            </div>
            <div className="right-footer">
                <a href={process.env.STAKE_VAULT_TELEGRAM} target="_blank" rel="noreferrer"><TeleContact/></a>
                <a href={process.env.STAKE_VAULT_X} target="_blank" rel="noreferrer"> <XContact/></a>
               
            </div>
            <ContactModal isClose={() => setOpenContact(false)} isOpen={isOpenContact}/>
        </footer>
    )
}

export default BaseFooter;