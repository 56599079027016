import React from "react";
import './index.css';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

import reason1 from '../../assets/image/reason_1.png'
import reason2 from '../../assets/image/reason_2.png'

function Reason() {
  const { t } = useTranslation(); 

  return <div className="intro-reason">
      <div className="content-block">
        <div className="title">
            <span className="normal-title">{t("whyStakeVault")}</span>
        </div>
        <div className="title">
        {
              i18n.language === "en" ? (
                <>
                  <span className="normal-title">{t("launchingSVN")}</span>
                  <span className="color-title">SVN Staking Blitz</span>
                </>) : (
                <>
                  <span className="color-title">SVN Staking Blitz</span>
                  <span className="normal-title">{t("launchingSVN")}</span>
                </>
              )
            }

        </div>
        <div className="grid">
            <div className="grid-item grid-item-text">
                <p className="grid-item-title">{t("100%ofTheFee")}</p>
                <p className="grid-item-description">{t("weWillUse")}</p>
            </div>

            <div className="grid-item grid-item-image grid-item-image-right">
              <img src={reason1} width="100%" height="100%" alt="reason1" />
            </div>

            <div className="grid-item grid-item-image grid-item-image-left">
                <img src={reason2} width="100%" height="100%" alt="reason1" />
            </div>

            <div className="grid-item  grid-item-text grid-item-text-right">
                <p className="grid-item-title">{t("futureDevelop")}</p>
                <p className="grid-item-description">{t("weAreAdvancing")}</p>
                <p className="grid-item-description">{t("additional")}</p>
                <p className="grid-item-description">{t("throughThese")}</p>
            </div>
        </div>
      </div>
  </div>;
}

export default Reason;
