import BigNumber from 'bignumber.js'
import { isNumber } from './is';

export function formatNumber(
  num: number | string,
  opt?: {
    decimals?: number
    decimal?: string
    separator?: string
    suffix?: string
    prefix?: string
    zero?: string
    bigN?: boolean
  }
) {
  if (!num) {
    if (opt?.zero) return opt?.zero
    num = 0
  }
  const { decimals = 2, decimal = '.', separator = ',', suffix = '', prefix = '', bigN = false } = opt || {}
  num = bigN ? new BigNumber(num).toFixed(decimals) : Number(num).toFixed(decimals)
  num += ''
  const x = num.split('.')
  x[1] && (x[1] = x[1].replace(/0+?$/g, ''))
  let x1 = x[0]
  const x2 = x.length > 1 && +x[1] > 0 ? decimal + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  if (separator && !isNumber(separator)) {
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, `$1${separator}$2`)
    }
  }
  return prefix + x1 + x2 + suffix
}

export const trimNum = (n: number) => {
  const _trim = String(n)
    .replace(/^0+/, '')
    .replace(/(?:\.0*|(\.\d+?)0+)$/, '$1') || '0'
  if (_trim.startsWith('.')) return '0' + _trim
  return _trim
}

