import React, { useEffect, useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { Link } from "react-router-dom";
import "../../index.css";
import DropdownIcon from "./components/Dropdown";
import Copy from "./components/Copy";
import { useTranslation } from "react-i18next";
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { truncateAddress } from "../../utils/formatData";
import ConnectedModal from "../Wallet/ConnectedModal";
import { Button, Modal } from "antd";

interface BaseHeaderProps {
  isShowStakeNow: boolean;
}

const BaseHeader: React.FC<BaseHeaderProps> = () => {
  const { address, isConnected } = useAccount();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isConnectedOpen, setIsConnectedOpen] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(localStorage.getItem("language") || "EN");
  const { i18n, t } = useTranslation();
  const [activeKey, setActiveKey] = useState('');
  const [showDisconnect, setShowDisconnect] = useState(false);

  const { disconnect } = useDisconnect();

  const changeLanguage = (lng: string) => {
    setLanguage(lng.toUpperCase());
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 950 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMenuOpen]);

  const modal = useWeb3Modal();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (lng: string) => {
    localStorage.setItem("language", lng.toUpperCase());
    changeLanguage(lng);
    setIsDropdownOpen(false);
  };

  const handleExit = async () => {
    try {
      await disconnect();
      setShowDisconnect(false);
      setShowDisconnect(false);
    } catch (err) {
      console.log('[Disconnect]', err)
    }
  }

  return (
    <div>
      <div className="header-container">
        <div className="left-header">
          <div className="logo-header">
            <h3><Link to="/" className="router-link-logo">STAKING VAULT</Link></h3>
          </div>
        </div>
        <div className="right-header">
          <button className={`menu-button ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </button>
          <div className="button-group">
            <div className="switch-button" onClick={toggleDropdown}>
              <div>{language === "EN" ? "EN" : "日本語"}</div>
              <DropdownIcon color={"#a1b2bf"} />
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <div className="dropdown-item" onClick={() => handleLanguageChange("en")}>EN</div>
                  <div className="dropdown-item" onClick={() => handleLanguageChange("ja")}>日本語</div>
                </div>
              )}
            </div>
            <div className="connect-wallet open-connect-wallet-btn">
              {isConnected ? (
                <div className="connect-wrap">
                  <Button
                    onClick={() => {
                      setIsConnectedOpen(true);
                      setActiveKey('stake');
                    }}
                    className="connect-wallet-button">Stake/Withdrawal</Button>
                  <div className="connect-wallet-button">
                    <span className="address-text"
                      onClick={() => setShowDisconnect(true)}>
                      {truncateAddress(String(address))}
                    </span>
                    <Copy contentCopy={String(address)} color="white" />
                  </div>
                </div>
              ) : (
                <button onClick={() => modal.open()} className="connect-wallet-button">
                  {t("connectWallet")}
                </button>
              )}
            </div>
          </div>
          <div className={`menu-overlay ${isMenuOpen ? "open" : ""}`}>
            <button className="switch-button-mobile" onClick={() => handleLanguageChange(language === "EN" ? "ja" : "en")}>
              {language === "EN" ? "EN" : "日本語"}
            </button>
            <div>
              {isConnected ? (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() => {
                      setIsConnectedOpen(true);
                      setActiveKey('stake');
                    }}
                    style={{ margin: '0 auto 20px' }}
                    className="connect-wallet-button">Stake/Withdrawal</Button>
                  <div className="connect-wallet-button">
                    <span className="address-text"
                      onClick={() => setShowDisconnect(true)}>
                      {truncateAddress(String(address))}
                    </span>
                    <Copy contentCopy={String(address)} color="white" />
                  </div>
                </div>
              ) : (
                <button onClick={() => modal.open()} className="connect-wallet-button">
                  {t("connectWallet")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConnectedModal
        isOpen={isConnectedOpen}
        isClose={() => setIsConnectedOpen(false)}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
      />

      <Modal
        title={'Account'}
        open={showDisconnect}
        onCancel={() => setShowDisconnect(false)}
        onOk={() => setShowDisconnect(false)}
        width={264}
        centered
        footer={null}
      >
        <div className='stake-disconnect'>
          <div>{address}</div>
          <div className="disconnect-btn"><Button onClick={handleExit}>Disconnect</Button></div>
        </div>
      </Modal>
    </div>
  );
};

export default BaseHeader;
