import React, { useEffect, useState } from 'react';
import { Radio, Pagination, Spin } from "antd";
import { useTranslation } from 'react-i18next';
import './history.css';
import { getHistoryList } from '../../../api/staking';
import stakeImg from '../../../assets/wallet/stake.svg';
import withdrawalImg from '../../../assets/wallet/withdrawal.svg';
import { formatNumber } from '../../../utils/purses';
import { StatusWithdrawal, TypeHistory } from '../../../constant/constant';
import Detail from './Detail';
import { parseTime } from '../../../utils/urlJoin';

interface TxData {
    id: number
    amount: string
    type: number
    txTime: number
    status: number
    txHash: string
    withdrawalHash: string
    withdrawalTime: number
    createdAt?: string
    updatedAt?: string
}

const History = (props: any) => {
    const { address } = props;
    const { t } = useTranslation();
    const [type, setType] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<TxData[]>([]);
    const [showDetail, setShowDetail] = useState(false);
    const [detail, setDetail] = useState<any>({});

    const getDataList = async (page = 1) => {
        setLoading(true);
        const {
            data: { list, total }
        } = await getHistoryList(
            {
                address,
                pageNum: page || 1,
                pageSize: 10,
                type
            },
        )
        setDataSource(list);
        setTotal(total);
        setLoading(false);
    }
    useEffect(() => {
        getDataList();
    }, [address, type]);

    return (
        <div className="history">
            <Spin spinning={loading} fullscreen />
            <div>
                <Radio.Group value={type} onChange={e => {
                    setType(e.target.value);
                }}>
                    <Radio.Button value={0} >All</Radio.Button>
                    <Radio.Button value={1}>Stake</Radio.Button>
                    <Radio.Button value={2}>Withdrawal</Radio.Button>
                </Radio.Group>
            </div>
            {dataSource.length ? <div className="history-wrap">
                {dataSource.map((item, index) => <div
                    key={JSON.stringify(item) + index}
                    className="transCell"
                    onClick={() => {
                        setShowDetail(true);
                        setDetail(item);
                    }}
                >
                    <div className="icon">
                        {item.type === 1 ?
                            <img src={stakeImg} alt="stake" /> :
                            <img src={withdrawalImg} alt="withdrawal" />
                        }
                    </div>
                    <div className="item-center">
                        <div className="center-title">
                            {TypeHistory.parse(item?.type)}
                        </div>
                        <div className="center-time">
                            {parseTime(item?.txTime, false, 'YYYY-MM-DD HH:mm')}
                        </div>
                    </div>
                    <div className="item-right">
                        <div className="right-amount">
                            {formatNumber(item?.amount, { suffix: ' ETH', decimals: 8 })}
                        </div>
                        {item.type !== 1 ? <div>
                            <span className={item.status === 1 ? 'text-brand-warn' : 'text-brand-main'}>
                                {
                                    StatusWithdrawal.parse(item.status)
                                }
                            </span>
                        </div> : null}
                    </div>
                </div>)}

                <Pagination
                    current={pageNum}
                    total={total}
                    pageSize={10}
                    size="small"
                    showTotal={(total) => `Total ${total} items`}
                    align="center"
                    style={{ marginTop: 10 }}
                    onChange={page => {
                        setPageNum(page);
                        getDataList(page);
                    }}
                />
            </div>
                :
                <div className="no-data">
                    <img src='https://stakingpool.hashkey.cloud/assets/empty-84c0cb49.svg' />
                    <div className="no-data-title">No Data</div>
                </div>}
            {showDetail && <Detail
                detail={detail}
                isOpen={showDetail}
                onClose={() => {
                    setShowDetail(false);
                    setDetail({});
                }}
            />}
        </div >
    )
}

export default History;
