import React, { useEffect, useState } from 'react';
import { Button, Modal, InputNumber, Spin, Popconfirm } from "antd";
import { useTranslation } from 'react-i18next';
import './withdraw.css';
import {
  DisconnectOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  useDisconnect, useWriteContract,
  useWaitForTransactionReceipt,
  useReadContract,
  useReadContracts,
} from 'wagmi'
import { formatNumber } from '../../../utils/purses';
import BigNumber from 'bignumber.js';
import PooledABI from '../../../api/pooled.json';
import { parseEther, toHex, formatEther } from 'viem';
import { fetchPrice, getDetailByHash } from '../../../api/staking';
import Detail from './Detail';
import productConfig from '../../../../product.config'
import { truncateAddress } from "../../../utils/formatData";

interface IModalSignMessage {
  address: string | undefined;
  isClose: () => void;
  activeKey: string;
  setBackResult: (v: string) => void;
}


const Withdrawal: React.FunctionComponent<IModalSignMessage> = (props) => {
  const { address, isClose, setBackResult } = props;

  let env = 'dev';
  const hostname = window.location.hostname;
  if (hostname.indexOf('staking.stakevaultnet.com') >= 0 && hostname.indexOf('dev-staking.stakevaultnet.com') < 0) {
    env = 'prod';
  }

  const currentConfig: any = productConfig[env || 'dev'];
  const { data: userEP } = useReadContract({
    address: currentConfig.pooledDepositManager,
    abi: PooledABI,
    functionName: 'userStatusMap',
    args: [address]
  });
  const [result, setResult] = useState('');
  const { t } = useTranslation();
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [withdrawAmount, setwithdrawAmount] = useState<null | number>(null);
  const { disconnect } = useDisconnect();
  const [receiptData, setReceiptData] = useState<any>({});
  const [price, setPrice] = useState<number>(1);
  const [rewardFee, setewardFee] = useState<any>(0);
  const [amoutFee, setAmoutFee] = useState<any>(0);
  const [withdrawals, setwithdrawals] = useState<any>({
    tolStaked: '0',
    tolRewards: '0',
  });
  const [showDetail, setShowDetail] = useState(false);
  const [detail, setDetail] = useState<any>({});
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(true);

  const handleExit = async () => {
    try {
      await disconnect();
      setShowDisconnect(false);
      isClose();
    } catch (err) {
      console.log('[Disconnect]', err)
    }
  }

  // Check Amount Input
  const isUnValidAmount = (value: any) => {
    if (!value && value !== 0) {
      return 'Please enter your amount.';
    }
    return ''
  }
  const { data, writeContract } = useWriteContract();
  const {
    data: receipt,
    isLoading
  } = useWaitForTransactionReceipt({ hash: data });

  const handlewithdraw = async () => {

    try {
      if (isUnValidAmount(withdrawAmount)) return;
      const tag = env === 'prod' ? currentConfig.tag : toHex('', { size: 32 })
      await writeContract({
        address: currentConfig.pooledDepositManager,
        functionName: 'withdrawETH',
        abi: PooledABI,
        args: [parseEther(String(withdrawAmount - Number(amoutFee | 0)))],
      })

    } catch (err: any) {
      console.log('[!!! Handlewithdraw Err]', [
        `Code: ${err?.code} | Message: ${err?.message}`,
        `ErrorCode: ${err?.errorCode} | Detail: ${err?.detail}`,
        err
      ])

    }
  }
  const rewardfeeResult = useReadContract({
    address: currentConfig.pooledDepositWallet,
    abi: [
      {
        "inputs": [],
        "name": "commissionPct",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ],
    functionName: 'commissionPct'
  });

  const PooledManagerContract: any = {
    address: currentConfig.pooledDepositManager,
    abi: PooledABI,
  }

  const result2 = useReadContracts({
    contracts: [
      {
        ...PooledManagerContract,
        functionName: 'calcETH',
        args: [userEP?.point]
      },
      {
        ...PooledManagerContract,
        functionName: 'getExchangeRate'
      },
    ],
  })

  const getWithdrawals = async () => {
    setLoading1(true);
    const [userTolETH, rateETH] = result2.data || {}
    const principal = new BigNumber(userEP?.principal);
    const tolRewards = new BigNumber(userTolETH.result as any).minus(principal)
    const safeTolRewards = tolRewards.isPositive() ? tolRewards : new BigNumber(0)
    const avaliable = principal.plus(safeTolRewards.multipliedBy(1 - rewardFee))
    const rateEP = new BigNumber(rateETH.result as any).div(1e18)
    const rateEPRewardFee = rateEP.minus(principal.div(userEP?.point)).multipliedBy(rewardFee)

    setwithdrawals({
      maxPoint: userEP?.point,
      tolStaked: principal.toString(),
      tolRewards: safeTolRewards.toString(),
      avaliable: avaliable.div(1e18).toNumber(),
      rateEP,
      rateEPRewardFee
    });
    setTimeout(() => {
      setLoading1(false);
    }, 300)
  }

  const getPrice = async () => {
    const { data: priceData } = await fetchPrice('USD', 'ethereum');
    setPrice(priceData.price || 1);
  }

  const getDetail = async (txhash: string) => {
    const { data } = await getDetailByHash({
      txhash,
      address
    });
    setDetail(data);
  }

  useEffect(() => {
    if (receipt && receipt.status) {
      setResult(receipt.status);
      setReceiptData(receipt);
      if (address) {
        getDetail(receipt.transactionHash);
      }
    }
  }, [receipt])

  useEffect(() => {
    if (rewardfeeResult && rewardfeeResult.status === 'success') {
      setewardFee(Number(rewardfeeResult.data || 0) / 10000);
      setTimeout(() => {
        setLoading2(false);
      }, 300)
    }
  }, [rewardfeeResult])

  useEffect(() => {
    getPrice()
  }, [])

  useEffect(() => {
    if (result2?.data && rewardFee > 0) {
      getWithdrawals();
    }
  }, [result2?.data, rewardFee])

  useEffect(() => {
    if (withdrawals.rateEP && withdrawals.rateEPRewardFee && withdrawAmount > 0) {
      const divider = new BigNumber(withdrawals.rateEP).div(withdrawals.rateEPRewardFee).minus(1);
      const amoutFeeData = divider.isPositive() ? new BigNumber(withdrawAmount).div(divider).toString() : 0;
      setAmoutFee(amoutFeeData);
    }
  }, [withdrawAmount, rewardFee])

  return (
    <div className="withdraw" key={`${result}withdraw`}>
      {result === 'success' ? <div className="success-wrap">
        <CheckCircleOutlined
          style={{
            fontSize: '40px',
            verticalAlign: 'middle',
            color: 'rgb(0, 93, 255)'
          }} />
        <div className="success-title">Request Successful</div>
        <div className="success-tips">Please wait patiently for the withdrawal.</div>
        <div className="block-hast-wrap">
          <a className="block-hast"
            target="_blank"
            href={`https://holesky.etherscan.io/tx/${receiptData.transactionHash}`}>
            {truncateAddress(receiptData.transactionHash)}
          </a>
          <ArrowRightOutlined
            onClick={() => {
              window.open(`https://holesky.etherscan.io/tx/${receiptData.transactionHash}`)
            }}
            style={{
              verticalAlign: 'middle',
              color: '#1677ff'
            }} />
        </div>
        <div className="btn-wrap">
          <Button
            type="primary"
            style={{
              width: '100%',
              height: 40,
              marginBottom: 10
            }}
            disabled={!detail.txHash}
            onClick={() => setShowDetail(true)}
          >{!detail.txHash ? 'Waiting' : 'View Detail'}</Button>
          <span onClick={() => {
            setResult('');
            setBackResult(`${Math.random()}`);
          }}>Back</span>
        </div>

      </div> : null}
      {result === 'failed' ? <div className="success-wrap">
        <CloseCircleOutlined
          style={{
            fontSize: '40px',
            verticalAlign: 'middle',
            color: 'red'
          }} />
        <div className="success-title">Failed</div>
        <div className="success-tips">Please try again later</div>
        <div className="btn-wrap">
          <span onClick={() => {
            setResult('');
          }}>Back</span>
        </div>
      </div> : null}
      {!result ? <div >
        <h3 className="withdraw-title">ETH Withdrawal</h3>
        <p className="withdraw-sub-title">You can withdraw ETH</p>
        <div className="withdraw-account">
          <span className='left'>Account</span>
          <span className='right'>
            <span className='address'>{truncateAddress(address)}</span>
            <DisconnectOutlined style={{
              fontSize: '18px',
              verticalAlign: 'middle'
            }}
              onClick={() => setShowDisconnect(true)} />
          </span>
        </div>
        <div className="availavle-money">
          <div>Available to withdraw</div>
          {loading1 || isNaN(formatEther(withdrawals?.tolStaked)) ? <Spin size="small" /> :
            <div className="Available-amount">{
              formatNumber(formatEther(withdrawals?.tolStaked || '0'), {
                decimals: 8,
                suffix: ' ETH'
              })
            }</div>}
          <div className="income-wrap">
            <div>
              <span>Total Staked</span>
              {loading1 ? <Spin size="small" /> : <span>{
                formatNumber(formatEther(withdrawals?.tolStaked), {
                  decimals: 8,
                  suffix: ' ETH'
                })
              }</span>}
            </div>
            <div>
              <span>Total Rewards</span>
              {loading1 ? <Spin size="small" /> : <span>{
                formatNumber(formatEther(withdrawals?.tolRewards), {
                  decimals: 8,
                  suffix: ' ETH'
                })
              }</span>}
            </div>
            <div>
              <span>Reward fee</span>
              {loading2 ? <Spin size="small" /> :
                <span>{
                  formatNumber(rewardFee * 100, { decimals: 2, suffix: '%' })
                }</span>}
            </div>
          </div>
        </div>

        <div>
          <div className="withdraw-money-input">
            <div className="enter-your-amount">Enter your amount</div>
            <div className="amount-center">
              <InputNumber
                value={withdrawAmount}
                min={Number(formatEther(withdrawals?.tolStaked || '0') || '0') <= 0 ? 0 : 0.001}
                max={Number(formatEther(withdrawals?.tolStaked || '0') || '0')}
                placeholder="0.00"
                controls={false}
                className="input-number"
                onChange={setwithdrawAmount} />
              <label className="amount-input-ETH">ETH</label>
            </div>
            <div className="">≈ $ {formatNumber(Number(withdrawAmount || 0) * (price || 1), { decimals: 2, zero: '0.00' })}</div>
          </div>

          <p className="input-error-tips">{isUnValidAmount(withdrawAmount || 0)}</p>
        </div>
        <div className="reward-fee">
          <span>Reward fee
            <Popconfirm
              title="Reward fee"
              description={<div style={{
                width: 200,
                wordWrap: 'break-word'
              }}>
                This fee applies to staking rewards only, and is NOT taken from your staked amount.
              </div>}
              showCancel={false}
              okText="Close"
            >
              <QuestionCircleOutlined style={{ marginLeft: 5 }} />
            </Popconfirm>
          </span>
          <span>
            {formatNumber(amoutFee, { decimals: 18, suffix: ' ETH' })}
          </span>
        </div>
        {env === 'prod' ? <div style={{ textAlign: 'left' }}>
          It takes 7 days for the account to arrive after request.
        </div> : null}

        <Button
          type="primary"
          disabled={!withdrawAmount}
          onClick={handlewithdraw}
          loading={isLoading}
          className="withdraw-btn"
        >Request Withdrawal</Button>
        <Modal
          title={'Account'}
          open={showDisconnect}
          onCancel={() => setShowDisconnect(false)}
          onOk={() => setShowDisconnect(false)}
          width={264}
          centered
          footer={null}
        >
          <div className='withdraw-disconnect'>
            <div>{address}</div>
            <div className="disconnect-btn"><Button onClick={handleExit}>Disconnect</Button></div>
          </div>
        </Modal>
      </div> : null}

      {showDetail && receiptData.status ? <Detail
        detail={{
          ...detail,
          withdrawalTime: detail?.txTime,
        }}
        isOpen={showDetail}
        onClose={() => {
          setShowDetail(false);
        }}
      /> : null}
    </div>
  )
}

export default Withdrawal;
