import React from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';


interface DataType {
    currency: string;
    stake: string;
    competitorA: string;
    competitorB: string;
}

const data: DataType[] = [
  {
    currency: "ETH",
    stake: "3.5~15%",
    competitorA: "4.4%",
    competitorB: "4~10%",
  },
  {
    currency: "ATOM",
    stake: "17~21%",
    competitorA: "14%",
    competitorB: "14.26%",
  },{
    currency: "SUI",
    stake: "5.5%",
    competitorA: "4.2%",
    competitorB: "3.05%",
  }
];
const StakingTable = () => {
  const { t } = useTranslation(); 
  const columns: TableColumnsType<DataType> = [
    {
        title: '',
        dataIndex: 'currency',
        width: 120
    },
    {
        title: () => {
          return t("thead_1");
        },
        dataIndex: 'stake',
        width: 120
    },
    {
      title: () => {
        return t("thead_2");
      },
        dataIndex: 'competitorA',
        width: 120,
    },
    {
      title: () => {
        return t("thead_3");
      },
        dataIndex: 'competitorB',
        width: 120,
    },
  ];

  return (
      <Table  pagination={false} columns={columns} dataSource={data} scroll={ {x: "max-content"}}/>
  )
};

export default StakingTable;