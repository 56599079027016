import axios from 'axios'
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse
} from 'axios'
import qs from 'qs'

interface BaseRes<T> {
  code: number
  msg: string
  data: T
}

type ApiRes<T> = BaseRes<T>

// 创建实例
const axiosInstance: AxiosInstance = axios.create({
  baseURL: '/api/goerli_pooled_staking/openapi/pooled_staking',
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'application/json'
  }
})

export interface RequestConfig extends AxiosRequestConfig {
  // 是否返回原始数据
  isReturnOriginData?: boolean
  // 下载文件名
  filename?: string
}

export interface Result<T = any> {
  code: number
  type: 'success' | 'error' | 'warning'
  msg: string
  data: T
}

const showErrorMessage = (code: number | string): string => {
  switch (code) {
    case 400:
      return '[400]:Request parameter error'
    case 401:
      return '[401]:Not authorised, please login'
    case 403:
      return '[403]:Access denied'
    case 404:
      return '[404]:Url not found'
    case 408:
      return '[408]:Request timeout'
    case 500:
      return '[500]:Internal server error'
    case 501:
      return '[501]:Service not implemented'
    case 502:
      return '[502]:Gateway error'
    case 503:
      return '[503]:Service not implemented'
    case 504:
      return '[504]:Gateway timeout'
    default:
      return `[${code}]:Connection error`
  }
}

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config: any) => {
    if (
      config.method === 'post' &&
      (config.headers as AxiosRequestHeaders)['Content-Type'] ===
      'application/x-www-form-urlencoded'
    ) {
      config.data = qs.stringify(config.data)
    }
    // get参数编码
    if (config.method === 'get' && config.params) {
      let url = config.url as string
      url += '?'
      const keys = Object.keys(config.params)
      for (const key of keys) {
        if (
          // eslint-disable-next-line no-void
          config.params[key] !== void 0 &&
          config.params[key] !== null &&
          config.params[key] !== ''
        ) {
          url += `${key}=${encodeURIComponent(config.params[key])}&`
        }
      }
      url = url.slice(0, Math.max(0, url.length - 1))
      config.params = {}
      config.url = url
    }
    ///  token
    // if (_token) {
    //   if (!config.headers) config.headers = { token: '' }
    //   config.headers.token = _token
    // }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  return response
})

const service = {
  request<T = any>(config: RequestConfig): Promise<ApiRes<T>> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .request<any, AxiosResponse<Result>>(config)
        .then((res: AxiosResponse<Result>) => {
          const resData = res?.data || { code: -1, msg: 'Error', data: null }
          const { code, msg } = resData
          if (code === 40301) {
            // userToken.value = ''
            // window.location.replace(LOGIN_PATH)
            reject(new Error(msg))
          } else if (config.isReturnOriginData) {
            resolve(resData as ApiRes<T>)
          } else if (code === 0) {
            resolve(resData as ApiRes<T>)
          } else {
            resolve(null as any)
          }
        })
        .catch((err: AxiosError) => {
          const response = err?.response
          if (response) {
            const msg = showErrorMessage(response.status)
            return reject(new Error(msg))
          }
          reject(err)
        })
    })
  },
  get<T = any>(url: string, params?: any, config?: RequestConfig): Promise<ApiRes<T>> {
    return service.request<T>({ ...config, url, method: 'GET', params })
  },
  post<T = any>(url: string, data?: any, config?: RequestConfig): Promise<ApiRes<T>> {
    return service.request<T>({ ...config, url, method: 'POST', data })
  },
  put<T = any>(url: string, data?: any, config?: RequestConfig): Promise<ApiRes<T>> {
    return service.request<T>({ ...config, url, method: 'PUT', data })
  },
  patch<T = any>(url: string, data?: any, config?: RequestConfig): Promise<ApiRes<T>> {
    return service.request<T>({ ...config, url, method: 'PATCH', data })
  },
  delete<T = any>(url: string, data?: any, config?: RequestConfig): Promise<ApiRes<T>> {
    return service.request<T>({ ...config, url, method: 'DELETE', data })
  },
  upload<T = any>(url: string, data?: FormData | File, config?: RequestConfig): Promise<ApiRes<T>> {
    return service.request<T>({
      ...config,
      url,
      method: 'POST',
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  download<T = any>(url: string, params?: any, config?: RequestConfig): Promise<ApiRes<T>> {
    return service.request<T>({
      ...config,
      url,
      method: 'GET',
      params,
      responseType: 'blob'
    })
  }
}

export { axiosInstance }
export default service