export function truncateAddress(address: string) {
  if (address.length < 10) return address;
  const start = address.slice(0, 6);
  const end = address.slice(-6);
  return `${start}...${end}`;
}

export function formatToPercentage(decimal: number): string {
  const number = parseFloat(decimal.toString());
  const percentage = (number * 100).toFixed(2);
  return `${percentage}%`;
}

export function shortenString(str: string, maxLength = 60) {
  if (str.length <= maxLength) return str;
  const partLength = Math.floor((maxLength - 3) / 2);
  return `${str.slice(0, partLength)}...${str.slice(-partLength)}`;
}

export function formatDateTime(isoString: string) {
  if (isoString.length === 0) return;

  const date = new Date(isoString);
  const options = { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit',
      timeZone: 'UTC' 
  };
  return date.toLocaleTimeString('en-GB', options).replace(',', '');
}