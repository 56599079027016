import { createConfig, CreateConnectorFn, http } from "wagmi";
import { holesky, mainnet } from "wagmi/chains";
import { walletConnect, coinbaseWallet } from "wagmi/connectors";

// 1. Get projectId from https://cloud.walletconnect.com
export const projectId = "3bee3dd4bbb98930c99da1d2c78e5bbe";

// 2. Create wagmiConfig
const metadata = {
  name: process.env.APP_NAME,
  description: process.env.DESCRIPTION,
  url: process.env.WEB_URL,
  icons: [process.env.ICONS],
};

const connectors: CreateConnectorFn[] = [];
connectors.push(walletConnect({ projectId, showQrModal: false }));
connectors.push(
  coinbaseWallet({
    appName: metadata.name,
    appLogoUrl: metadata.icons[0],
  })
);

const mainnetConfig = createConfig({
  chains: [mainnet],
  connectors,
  transports: {
    [mainnet.id]: http(),
  },
});

const testnetConfig = createConfig({
  chains: [holesky],
  connectors,
  transports: {
    [holesky.id]: http(),
  },
});

// export const config =
//   process.env.NETWORK_MODE?.toLowerCase() === "mainnet"
//     ? mainnetConfig
//     : testnetConfig;

const hostname = window.location.hostname;
export const config =
  hostname.indexOf('staking.stakevaultnet.com') >= 0 && hostname.indexOf('dev-staking.stakevaultnet.com') < 0
    ? mainnetConfig
    : testnetConfig;