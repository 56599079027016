import React from 'react';
import { Modal, Button, Popconfirm } from "antd";
import { useTranslation } from 'react-i18next';
import './detail.css';
import stakeImg from '../../../assets/wallet/stake.svg';
import withdrawalImg from '../../../assets/wallet/withdrawal.svg';
import { formatNumber } from '../../../utils/purses';
import { StatusWithdrawal, TypeHistory } from '../../../constant/constant';
import { parseTime } from '../../../utils/urlJoin';
import { ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { truncateAddress } from "../../../utils/formatData";

interface TxData {
  id: number
  amount: string
  type: number
  txTime: number
  status: number
  txHash: string
  withdrawalHash: string
  withdrawalTime: number
  createdAt?: string
  updatedAt?: string
}

interface PropsOpt {
  detail: TxData;
  isOpen: boolean;
  onClose: () => void;
}

const Detail = (props: PropsOpt) => {
  const { detail, isOpen, onClose } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={364}
      centered
      style={{
        backgroundColor: '#fff!important',
      }}
    >
      <div className="detail">
        <div className="detail-top">
          <div className="icon">
            {detail.type === 1 ?
              <img src={stakeImg} alt="stake" /> :
              <img src={withdrawalImg} alt="withdrawal" />
            }
          </div>
          <div className="detail-type">
            {TypeHistory.parse(detail?.type)}
          </div>
          <div className="detail-amount">
            {formatNumber(detail?.amount || 0, { suffix: ' ETH', decimals: 8, zero: '--' })}
          </div>
        </div>
        {detail.type === 2 ? <div className="detail-content">
          <p className="subTitle">Request</p>
          <div className="cell-row">
            <span>Request</span>
            <span>{StatusWithdrawal.parse(3)}</span>
          </div>
          <div className="cell-row">
            <span>Timestamp (UTC)</span>
            <span>{parseTime(detail?.txTime, false, 'YYYY.MM.DD hh:mm:ss')}</span>
          </div>
          <div className="cell-row">
            <span>TxHash</span>
            <span>
              <a className="txHash"
                target="_blank"
                href={`https://holesky.etherscan.io/tx/${detail.txHash}`}>
                {truncateAddress(detail.txHash)}
              </a>
              <ArrowRightOutlined
                onClick={() => {
                  window.open(`https://holesky.etherscan.io/tx/${detail.txHash}`)
                }}
                style={{
                  verticalAlign: 'middle',
                  color: '#1677ff'
                }} />
            </span>
          </div>
        </div> : null}
        {detail.type === 2 ? <p className="subTitle">Withdrawal</p> : null}
        <div className="detail-content">
          <div className="cell-row">
            <span>Status
              {detail.type === 2 ? <Popconfirm
                title="Status"
                description={<div style={{
                  width: 200,
                  wordWrap: 'break-word'
                }}>
                  The status will update when the block which excludes the transaction has been finalized.
                </div>}
                showCancel={false}
                okText="Close"
              >
                <QuestionCircleOutlined style={{ marginLeft: 5 }} />
              </Popconfirm> : null}
            </span>
            <span
              style={{
                color: detail?.status === 1 ? 'rgb(242, 154, 22)' : 'rgb(14, 11, 61)'
              }}
            >{detail.type === 2 ? StatusWithdrawal.parse(detail?.status) :
              StatusWithdrawal.parse(3)
              }</span>
          </div>
          <div className="cell-row">
            <span>Timestamp (UTC)</span>
            {detail.type === 1 ?
              <span>{parseTime(detail?.txTime, false, 'YYYY-MM-DD hh:mm:ss')}</span>
              : detail?.status === 1 ? <span>--</span> :
                <span>{parseTime(detail?.withdrawalTime, false, 'YYYY-MM-DD hh:mm:ss')}</span>}
          </div>
          <div className="cell-row">
            <span>TxHash</span>
            {detail?.status === 1 ? <span>--</span> :
              <span>
                <a className="txHash"
                  target="_blank"
                  href={`https://holesky.etherscan.io/tx/${detail.txHash}`}>
                  {truncateAddress(detail.txHash)}
                </a>
                <ArrowRightOutlined
                  onClick={() => {
                    window.open(`https://holesky.etherscan.io/tx/${detail.txHash}`)
                  }}
                  style={{
                    verticalAlign: 'middle',
                    color: '#1677ff'
                  }} />
              </span>}
          </div>
        </div>
        <div className='btn-wrap'>
          <Button
            type="primary"
            style={{ width: 140 }}
            onClick={onClose}>Close</Button>
        </div>
      </div >
    </Modal >
  )
}

export default Detail;
