import React from "react";
import './index.css';
import participate1 from '../../assets/image/participate_1.png'
import participate2 from '../../assets/image/participate_2.png'
import participate3 from '../../assets/image/participate_3.png'
import participate4 from '../../assets/image/participate_4.png'
import { useTranslation } from "react-i18next";

function Participate() {
  const { t } = useTranslation (); 

  return <div className="participate">
      <p className="title">{t("participate_title")}</p>
      <p className="description">{t("participate_des")}</p>
      <div className="participate-content">
        <div className="participate-item first-item">
          <img src={participate1} width="92" height="92" alt="participate1" />
          <div className="participate-item-content">
            <div className="item-blur">
              <span className="blur" style={{backgroundColor: "rgba(49, 94, 255, 0.9)"}} />
            </div>    
            <p className="item-title">01</p>
            <p className="item-description">{t("participate_1")}</p>
          </div>
        </div>
        <div className="participate-item second-item">
          <img src={participate2} width="92" height="92" alt="participate2" />
          <div className="participate-item-content">
            <div className="item-blur">
              <span className="blur" style={{backgroundColor: "rgba(247, 147, 26, 0.9)"}} />
            </div>
            <p className="item-title">02</p>
            <p className="item-description">{t("participate_2")}</p>
          </div>
        </div>
        <div className="participate-item second-item">
          <img src={participate3} width="92" height="92" alt="participate3" />
          <div className="participate-item-content">
            <div className="item-blur">
              <span className="blur" style={{backgroundColor: "rgba(247, 147, 26, 0.9)"}} />
            </div>
            <p className="item-title">03</p>
            <p className="item-description">{t("participate_3")}</p>
          </div>
        </div>
        <div className="participate-item second-item">
          <img src={participate4} width="92" height="92" alt="participate4" />
          <div className="participate-item-content">
            <div className="item-blur">
              <span className="blur" style={{backgroundColor: "rgba(8, 178, 126, 0.9)"}} />
            </div>
            <p className="item-title">04</p>
            <p className="item-description">{t("participate_4")}</p>
          </div>
        </div>
      </div>
  </div>;
}

export default Participate;
