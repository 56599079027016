import React, { FunctionComponent } from "react";

interface TeleContactProps {}

const TeleContact: FunctionComponent<TeleContactProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M23.5504 4.05015L20.0254 20.5501C19.7629 21.7126 19.0879 21.9751 18.1129 21.4501L12.8254 17.5501L10.238 20.0251C9.97545 20.2876 9.71295 20.5501 9.11295 20.5501L9.52545 15.1126L19.3879 6.15015C19.8004 5.73765 19.2754 5.58765 18.7505 5.92515L6.48795 13.6501L1.20045 12.0376C0.0379501 11.6626 0.0379501 10.8751 1.46295 10.3501L22.0129 2.36265C23.0254 2.06265 23.888 2.58765 23.5504 4.05015Z"
        fill="#D0D8DF"
      />
    </svg>
  );
};

export default TeleContact;
