import React from "react";
import './index.css';
import { useTranslation } from "react-i18next";

function IntroHeader() {
  const { t } = useTranslation(); 

  return <div className="intro-header">
      <div className="content">
          <p className="title">{t("logo_intro")}</p>
          <p className="sub-title">Power to Decentralized Finance</p>

          
          <p className="description first-description">{t("des_intro_1")}</p>
          <p className="description second-description">{t("des_intro_2")}</p>
      </div>
  </div>;
}

export default IntroHeader;
