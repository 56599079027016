// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connected-modal {
  background-color: #fff;
  border-radius: 6px;
  height: 80vh;
  position: relative;
}

.connected-modal .ant-modal-content {
  background-color: #fff !important;
  overflow-y: auto;
}

.connected-modal .ant-modal-content>div {
  height: 80vh;
}

.connected-modal .ant-tabs-tab {
  font-size: 20px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/Wallet/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".connected-modal {\n  background-color: #fff;\n  border-radius: 6px;\n  height: 80vh;\n  position: relative;\n}\n\n.connected-modal .ant-modal-content {\n  background-color: #fff !important;\n  overflow-y: auto;\n}\n\n.connected-modal .ant-modal-content>div {\n  height: 80vh;\n}\n\n.connected-modal .ant-tabs-tab {\n  font-size: 20px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
