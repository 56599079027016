export enum StatusEnum {
  DRAFT = 0,
  SUBMITTED = 1,
  PENDING = 2,
  ACTIVE = 3,
  DEACTIVE = 4,
  "PENDING WITHDRAWAL" = 5,
}

type TypeOpt = {
  label: string
  value: any
}

export const OPTS_HISTORY_TAB: TypeOpt[] = [
  { label: 'All', value: 0 },
  { label: 'Stake', value: 1 },
  { label: 'Withdrawal', value: 2 }
]

export const OPTS_WITHDRAWAL: TypeOpt[] = [
  { label: 'Waiting for withdrawal', value: 1 },
  { label: 'To be claimed', value: 2 },
  { label: 'Completed', value: 3 }
]

class OptHelper {
  list: TypeOpt[]
  constructor(_list: TypeOpt[]) {
    this.list = [..._list]
  }
  parse(val: any) {
    const _target = this.list.find(e => e.value === val)
    return _target?.label || ''
  }
}

export const TypeHistory = new OptHelper(OPTS_HISTORY_TAB)
export const StatusWithdrawal = new OptHelper(OPTS_WITHDRAWAL)

export const BADE_URL = {
  goerli: 'https://api.hashquark-stg.io/goerli-dashboard',
  prod: 'https://api.hashkey.cloud/wealth',
  qa: 'https://api.hashquark-qa.io/capital-dashboard',
  stage: 'https://api.hashquark-stg.io/dashboard',
}