const productConfig = {
  prod: {
    pooledDepositManager: "0x1AAa3D9f104117f06eD815cEcc9ef0C6a831c67F",
    pooledDepositWallet: "0xbf3B82a68631fB85e611c57eB6F0d75bEae12765",
    tag: "0xbfc9f29ec32ccf8d5de7085ff1b965e1fbeefb39e2a5aff7a857e143ef6545a4",
  },
  dev: {
    pooledDepositManager: "0x7454473D3886B94376B17D781172F2f1181B8CA9",
    pooledDepositWallet: "0x6b4Ed0Fb2E070fe4ed73d61d10Ab9f2e8B5cd9e0",
  }
}

export default productConfig;