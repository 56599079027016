import React, { useEffect, useState } from 'react';
import { Button, Modal, InputNumber, Spin, Checkbox, Popconfirm } from "antd";
import { useTranslation } from 'react-i18next';
import './stake.css';
import {
  DisconnectOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import {
  useDisconnect, useWriteContract,
  useWaitForTransactionReceipt,
  useReadContract,
  useAccount,
  useBalance,
} from 'wagmi'
import { formatNumber, } from '../../../utils/purses';
import BigNumber from 'bignumber.js';
import PooledABI from '../../../api/pooled.json';
import { parseEther, toHex } from 'viem';
import { fetchPrice, fetchAPR, getDetailByHash } from '../../../api/staking'
import Detail from './Detail';
import productConfig from '../../../../product.config'
import { truncateAddress } from "../../../utils/formatData";

interface IModalSignMessage {
  isClose: () => void;
  setBackResult: (v: string) => void;
}

const Stake: React.FunctionComponent<IModalSignMessage> = (props) => {
  const { isClose, setBackResult } = props;
  const [result, setResult] = useState('');
  const { t } = useTranslation();
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [stakeAmount, setStakeAmount] = useState<null | number>(null);
  const { disconnect } = useDisconnect();
  const [receiptData, setReceiptData] = useState<any>({});
  const [price, setPrice] = useState<number>(1);
  const [rewardFee, setewardFee] = useState<any>(0);
  const [apr7D, setapr7D] = useState<any>(1);
  const [showDetail, setShowDetail] = useState(false);
  const [detail, setDetail] = useState<any>({});
  const [accountInfo, setAccountInfo] = useState<any>({});
  // sum of money  loading1
  // APR loading2
  // Reward fee  loading3
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [calcAnnually, setcalcAnnually] = useState<number>(0);
  const [calcMonthly, setcalcMonthly] = useState<number>(0);
  // Calibrate for reconfirmation
  const [isChecked, setIsChecked] = useState(false);

  const account = useAccount();
  let env = 'dev';
  const hostname = window.location.hostname;
  if (hostname.indexOf('staking.stakevaultnet.com') >= 0 && hostname.indexOf('dev-staking.stakevaultnet.com') < 0) {
    env = 'prod';
  }

  const { data: accountData } = useBalance({
    address: account.address,
    chainId: account.chainId
  });

  const handleExit = async () => {
    try {
      await disconnect();
      setShowDisconnect(false);
      isClose();
    } catch (err) {
      console.log('[Disconnect]', err)
    }
  }
  // Check Amount Input
  const isUnValidAmount = (value: any) => {
    if (!value && value !== 0) {
      return 'Please enter your amount.';
    }
    return ''
  }
  const { data, writeContract } = useWriteContract();
  const {
    data: receipt,
    isLoading
  } = useWaitForTransactionReceipt({ hash: data });

  const currentConfig: any = productConfig[env || 'dev'];

  const handleStake = async () => {
    try {
      if (isUnValidAmount(stakeAmount)) return;

      const tag = env === 'prod' ? currentConfig.tag : toHex('', { size: 32 })
      await writeContract({
        address: currentConfig.pooledDepositManager,
        functionName: 'depositETH',
        abi: PooledABI,
        args: [tag],
        value: parseEther(String(stakeAmount))
      })
    } catch (err: any) {
      console.log('[!!! HandleStake Err]', [
        `Code: ${err?.code} | Message: ${err?.message}`,
        `ErrorCode: ${err?.errorCode} | Detail: ${err?.detail}`,
        err
      ])
    }
  }

  const rewardfeeResult = useReadContract({
    address: currentConfig.pooledDepositWallet,
    abi: [
      {
        "inputs": [],
        "name": "commissionPct",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ],
    functionName: 'commissionPct'
  });

  const getPrice = async () => {
    setLoading2(true);
    setLoading3(true);
    const { data: priceData } = await fetchPrice('USD', 'ethereum');
    const { data: APRData } = await fetchAPR({ date: new Date(), limit: 7 });
    setPrice(priceData.price || 1);
    setapr7D(APRData && APRData[0] ? APRData[0]?.avg_year_apr.replace('%', '') : {});
    await setLoading2(false)
    await setLoading3(false)
  }

  const getDetail = async (txhash: string) => {
    const { data } = await getDetailByHash({
      txhash,
      address: account.address,
    });
    if (data) setDetail(data);
  }

  useEffect(() => {
    if (receipt && receipt.status) {
      setResult(receipt.status);
      setReceiptData(receipt);
      if (account?.address && receipt.transactionHash) {
        getDetail(receipt.transactionHash);
      }
    }
  }, [receipt])

  useEffect(() => {
    if (rewardfeeResult && rewardfeeResult.status === 'success') {
      setewardFee(Number(rewardfeeResult.data || 0) / 10000);
    }
  }, [rewardfeeResult])

  useEffect(() => {
    getPrice()
  }, [])

  useEffect(() => {
    if (accountData?.formatted) {
      setAccountInfo(accountData);
      setTimeout(() => {
        setLoading1(false);
      }, 200)
    }
  }, [accountData])


  useEffect(() => {
    const calcAnnuallyNum = new BigNumber(Number(stakeAmount || 0))
      .multipliedBy(apr7D / 100)
      .multipliedBy(1 - rewardFee)
      .toNumber();
    setcalcAnnually(calcAnnuallyNum);
    const calcMonthlyNum = new BigNumber(calcAnnuallyNum).multipliedBy(30 / 365).toNumber()
    setcalcMonthly(calcMonthlyNum)
  }, [apr7D, rewardFee, stakeAmount])

  return (
    <div className="stake">
      {result === 'success' ? <div className="success-wrap">
        <CheckCircleOutlined
          style={{
            fontSize: '40px',
            verticalAlign: 'middle',
            color: 'rgb(0, 93, 255)'
          }} />
        <div className="success-title">Success</div>
        <div className="success-tips">You have staked {stakeAmount} ETH.</div>
        <div className="block-hast-wrap">
          <a className="block-hast"
            target="_blank"
            href={`https://holesky.etherscan.io/tx/${receiptData.transactionHash}`}>
            {truncateAddress(receiptData.transactionHash)}
          </a>
          <ArrowRightOutlined
            onClick={() => {
              window.open(`https://holesky.etherscan.io/tx/${receiptData.transactionHash}`)
            }}
            style={{
              verticalAlign: 'middle',
              color: '#1677ff'
            }} />
        </div>
        <div className="btn-wrap">
          <Button
            type="primary"
            style={{
              width: '100%',
              height: 40,
              marginBottom: 10
            }}
            disabled={!detail.txHash}
            onClick={() => setShowDetail(true)}
          >{!detail.txHash ? 'Waiting' : 'View Detail'}</Button>
          <span onClick={() => {
            setResult('');
            setBackResult(`${Math.random()}`);
          }}>Back</span>
        </div>

      </div> : null}
      {result && result !== 'success' ? <div className="success-wrap">
        <CloseCircleOutlined
          style={{
            fontSize: '40px',
            verticalAlign: 'middle',
            color: 'red'
          }} />
        <div className="success-title">Failed</div>
        <div className="success-tips">Please try again later</div>
        <div className="btn-wrap">
          <span onClick={() => {
            setResult('');
          }}>Back</span>
        </div>
      </div> : null}
      {!result ? <div key={`${result}stake`}>
        <h3 className="stake-title">Stake ETH</h3>
        <p className="stake-sub-title">You can stake any amount of ETH</p>
        <div className="stake-account">
          <span className='left'>Account</span>
          <span className='right'>
            <span className='address'>{truncateAddress(account?.address)}</span>
            <DisconnectOutlined style={{
              fontSize: '18px',
              verticalAlign: 'middle'
            }}
              onClick={() => setShowDisconnect(true)} />
          </span>
        </div>
        <div className="availavle-money">
          <div>Available to stake</div>
          {loading1 ? <Spin size="small" /> :
            <div>{formatNumber(accountInfo.formatted, { suffix: ' ETH', decimals: 4, zero: '0.0 ETH' })}</div>}
        </div>

        <div>
          <div className="stake-money-input">
            <div className="enter-your-amount">Enter your amount</div>
            <div className="amount-center">
              <InputNumber
                value={stakeAmount}
                min={formatNumber(accountInfo.formatted, { decimals: 4, zero: '0.00' }) <= 0 ? 0 : 0.001}
                placeholder="0.00"
                controls={false}
                className="input-number"
                max={formatNumber(accountInfo.formatted, { decimals: 4, zero: '0.00' })}
                onChange={setStakeAmount}
              // onChange={setResult}
              />
              <label className="amount-input-ETH">ETH</label>
            </div>
            <div className="">≈ $ {formatNumber(Number(stakeAmount) * (price || 1), { decimals: 2, zero: '0.00' })}</div>
          </div>

          <p className="input-error-tips">{isUnValidAmount(stakeAmount)}</p>
        </div>
        <div className="income-wrap">
          <div>
            <span>APR
              <Popconfirm
                title="APR"
                description={<div style={{
                  width: 200,
                  wordWrap: 'break-word'
                }}>
                  The APR (Annual Percentage Rate) is the overall Consensus Layer (CL) and Execution Layer (EL) rewards received by validators to total pooled ETH estimated as the moving average of the last seven days.
                </div>}
                showCancel={false}
                okText="Close"
              >
                <QuestionCircleOutlined style={{ marginLeft: 5 }} />
              </Popconfirm>
            </span>
            {loading2 ? <Spin size="small" /> :
              <span>{formatNumber(apr7D, { decimals: 2, suffix: '%' })}</span>}

          </div>
          <div>
            <span>Reward fee
              <Popconfirm
                title="Reward fee"
                description={<div style={{
                  width: 200,
                  wordWrap: 'break-word'
                }}>
                  This fee applies to staking rewards only, and is NOT taken from your staked amount.
                </div>}
                showCancel={false}
                okText="Close"
              >
                <QuestionCircleOutlined style={{ marginLeft: 5 }} />
              </Popconfirm>
            </span>
            {loading3 ? <Spin size="small" /> :
              <span>{formatNumber(rewardFee * 100, { decimals: 2, suffix: '%' })}</span>}
          </div>
          <div className="will-receive">You will receive</div>
          <div>
            <span>Monthly</span>
            <span>{
              formatNumber(calcMonthly, { decimals: 18, suffix: ' ETH', bigN: true })
            }</span>
          </div>
          <div>
            <span>Annualiy</span>
            <span>{
              formatNumber(calcAnnually, { decimals: 18, suffix: ' ETH', bigN: true })
            }</span>
          </div>
        </div>
        <div className="bottom-tips">
          Your earnings data will be updated daily at 0:00 UTC and can be viewed at Withdrawal.
        </div>

        <div className="stake-btn">
          <Checkbox
            checked={isChecked}
            onChange={e => {
              setIsChecked(e.target.checked)
            }}>I Agree to Stake</Checkbox>
          <Button
            type="primary"
            disabled={!stakeAmount || !isChecked}
            onClick={handleStake}
            loading={isLoading}
            className="stake-button"
          >Stake</Button>
        </div>
        <Modal
          title={'Account'}
          open={showDisconnect}
          onCancel={() => setShowDisconnect(false)}
          onOk={() => setShowDisconnect(false)}
          width={264}
          centered
          footer={null}
        >
          <div className='stake-disconnect'>
            <div>{account?.address}</div>
            <div className="disconnect-btn"><Button onClick={handleExit}>Disconnect</Button></div>
          </div>
        </Modal>
      </div> : null}

      {showDetail && receiptData.status ? <Detail
        detail={detail}
        isOpen={showDetail}
        onClose={() => {
          setShowDetail(false);
        }}
      /> : null}
    </div >
  )
}

export default Stake;
