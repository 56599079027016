import React, { useEffect, useState } from 'react';
import { Modal, Tabs } from "antd";
import { useAccount, useBalance, useReadContract } from 'wagmi'
import { useTranslation } from 'react-i18next';
import './index.css';
import Stake from './components/Stake';
import Withdrawal from './components/Withdrawal';
import History from './components/History';

const { TabPane } = Tabs;
interface IModalSignMessage {
  isOpen?: boolean;
  isClose: () => void;
  activeKey: string;
  setActiveKey: (v: string) => void;
}

const ConnectedModal: React.FunctionComponent<IModalSignMessage> = (props) => {

  // const [activeKey, setActiveKey] = useState('withdrawal');
  const { isOpen, isClose, activeKey, setActiveKey } = props
  const account = useAccount();
  const [backResult, setBackResult] = useState('');
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        title=''
        open={isOpen}
        onCancel={isClose}
        onOk={isClose}
        width={464}
        centered
        footer={null}
        className="connected-modal"
        style={{
          backgroundColor: '#fff!important',
        }}
      >
        <div className='modal-connected'>
          <Tabs activeKey={activeKey} onChange={(v) => {
            setActiveKey(v);
            setBackResult(`${Math.random()}`)
          }} key={activeKey}>
            <TabPane tab="Stake" key="stake">
              <Stake
                key={`${backResult}${activeKey}`}
                isClose={isClose}
                setBackResult={setBackResult} />
            </TabPane>
            <TabPane tab="Withdrawal" key="withdrawal">
              <Withdrawal
                activeKey={activeKey}
                address={account.address}
                isClose={isClose}
                key={`${backResult}${activeKey}`}
                setBackResult={setBackResult} />
            </TabPane>
            <TabPane tab="History" key="history">
              <History address={account.address} />
            </TabPane>
          </Tabs>
        </div>
      </Modal>
    </div>
  )
}

export default ConnectedModal;
