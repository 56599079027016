import service from '../utils/request'
import { urlJoin, } from '../utils/urlJoin'
const MOCK_ADDR = '';
import { BADE_URL } from '../constant/constant';
import { copyFile } from 'fs';
// const MOCK_ADDR = '0xD29f1f0810f3E679c2D589e5A72EFB4639B606E3'

class ModStaker {
  base = '/users'
  addr: string
  constructor({ addr }: { addr: string }) {
    this.addr = MOCK_ADDR || addr
  }
  _quickUrl(slug: string) {
    return urlJoin(this.base, this.addr, slug)
  }

  /* API */

  history_list(page: any, params?: { type?: number }) {
    return service.get<{ list: any, total: number }>(
      this._quickUrl('/history_list'), { ...page, ...params }
    )
  }
  historyByTxHash(txhash: string) {
    return service.get<any>(this._quickUrl(`/history/${txhash}`))
  }
  whenWithdrawal = () => {
    return service.get<{ can_withdraw_block: string }>(this._quickUrl(`/withdraw_possible`))
  }
}

export const StakerApi = (addr: string) => new ModStaker({ addr })

let env = 'env';
const hostname = window.location.hostname;
if (hostname.indexOf('localhost') >= 0) {
  env = 'dev';
}
else if (hostname.indexOf('dev-staking.stakevaultnet.com') >= 0) {
  env = 'goerli';
}
else if (hostname.indexOf('staking.stakevaultnet.com') >= 0 && hostname.indexOf('dev-staking.stakevaultnet.com') < 0) {
  env = 'prod';
}
export const fetchPrice = (symbol: string, coinId: string) => {
  return service.get<{
    /**
     * 虚拟货币
     */
    coinId: string;
    /**
     * 现实货币
     */
    currency: string;
    /**
     * 汇率，1584.6698315639387
     */
    price: number;
    /**
     * 此汇率时间（UTC）
     */
    time: string;
  }>(
    `/coins/${symbol}/latest`,
    { coinId },
    { baseURL: env === 'dev' ? '/api/goerli-dashboard' : BADE_URL[env] }
  )
}

export const fetchAPR = (parmas: { date: any, limit: number }) => {
  return service.get(
    `/openapi/pooled_staking/rewards`,
    { ...parmas, },
    {
      baseURL: env === 'dev' ? '/api/goerli_pooled_staking' :
        env === 'prod' ? 'https://api.hashkey.cloud/pooled_staking' :
          'https://api.hashquark-stg.io/goerli_pooled_staking'
    }
  )
}

export const getHistoryList = (parmas: any) => {
  return service.get(
    `/openapi/pooled_staking/users/${parmas.address}/history_list`,
    { ...parmas, },
    {
      baseURL: env === 'dev' ? '/api/goerli_pooled_staking' :
        env === 'prod' ? 'https://api.hashkey.cloud/pooled_staking' :
          'https://api.hashquark-stg.io/goerli_pooled_staking'
    })
}

export const getDetailByHash = (parmas: any) => {
  return service.get(
    `/openapi/pooled_staking/users/${parmas.address}/history/${parmas.txhash}`,
    { ...parmas, },
    {
      baseURL: env === 'dev' ? '/api/goerli_pooled_staking' :
        env === 'prod' ? 'https://api.hashkey.cloud/pooled_staking' :
          'https://api.hashquark-stg.io/goerli_pooled_staking'
    })
}


