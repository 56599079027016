import React, { FunctionComponent, useState } from "react";
interface CopyProps {
  contentCopy: string;
  color: string;
}

const Copy: FunctionComponent<CopyProps> = ({ contentCopy, color }) => {
  const [isShowCopySuccess, setShowCopySuccess] = useState<boolean>(false);

  const handleCopy = (content: string) => {
    setShowCopySuccess(true);
    navigator.clipboard.writeText(content);
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 2000);
  };
  return (
    <span onClick={() => handleCopy(contentCopy)}>
      {isShowCopySuccess ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M22.7249 5.02498C22.3874 4.68748 21.8624 4.68748 21.5249 5.02498L8.77491 17.4L2.47491 11.2125C2.13741 10.875 1.61241 10.9125 1.27491 11.2125C0.937413 11.55 0.974913 12.075 1.27491 12.4125L7.91241 18.8625C8.13741 19.0875 8.43741 19.2 8.77491 19.2C9.11241 19.2 9.37491 19.0875 9.63741 18.8625L22.7249 6.14998C23.0624 5.88748 23.0624 5.36248 22.7249 5.02498Z"
            fill={color}
          />
        </svg>
      ) : (
        <svg
          onClick={() => handleCopy(contentCopy)}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M12.9166 16.6667H4.58329V5.83335C4.58329 5.37502 4.20829 5.00002 3.74996 5.00002C3.29163 5.00002 2.91663 5.37502 2.91663 5.83335V16.6667C2.91663 17.5834 3.66663 18.3334 4.58329 18.3334H12.9166C13.375 18.3334 13.75 17.9584 13.75 17.5C13.75 17.0417 13.375 16.6667 12.9166 16.6667ZM17.0833 13.3334V3.33335C17.0833 2.41669 16.3333 1.66669 15.4166 1.66669H7.91663C6.99996 1.66669 6.24996 2.41669 6.24996 3.33335V13.3334C6.24996 14.25 6.99996 15 7.91663 15H15.4166C16.3333 15 17.0833 14.25 17.0833 13.3334ZM15.4166 13.3334H7.91663V3.33335H15.4166V13.3334Z"
            fill={color}
          />
        </svg>
      )}
    </span>
  );
};

export default Copy;
