// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history {
    padding-bottom: 20px;
}

.history .no-data {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    padding: 82px 0 50px;
}

.no-data-title {
    text-align: center;
    color: rgb(83, 84, 121);
    margin-top: 10px;
}

.transCell {
    display: flex;
    margin-top: 16px;
}

.transCell .item-center {
    margin-left: 8px;
}

.transCell .item-center .center-title {
    color: rgb(14, 11, 61);
    font-size: 20px;
    font-weight: 600;
}

.transCell .item-center .center-time {
    color: rgb(179, 184, 208);
    font-size: 16px;
}

.item-right {
    margin-left: auto;
    text-align: right;
}

.item-right .right-amount {
    color: rgb(14, 11, 61);
    font-weight: 700;
    font-size: 18px;
}

.item-right .text-brand-main {
    color: rgb(83, 84, 121);
    text-align: right;
    font-size: 14px;
}

.item-right .text-brand-warn {
    color: rgb(242, 154, 22);
    text-align: right;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Wallet/components/history.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".history {\n    padding-bottom: 20px;\n}\n\n.history .no-data {\n    width: 150px;\n    height: 150px;\n    margin: 0 auto;\n    padding: 82px 0 50px;\n}\n\n.no-data-title {\n    text-align: center;\n    color: rgb(83, 84, 121);\n    margin-top: 10px;\n}\n\n.transCell {\n    display: flex;\n    margin-top: 16px;\n}\n\n.transCell .item-center {\n    margin-left: 8px;\n}\n\n.transCell .item-center .center-title {\n    color: rgb(14, 11, 61);\n    font-size: 20px;\n    font-weight: 600;\n}\n\n.transCell .item-center .center-time {\n    color: rgb(179, 184, 208);\n    font-size: 16px;\n}\n\n.item-right {\n    margin-left: auto;\n    text-align: right;\n}\n\n.item-right .right-amount {\n    color: rgb(14, 11, 61);\n    font-weight: 700;\n    font-size: 18px;\n}\n\n.item-right .text-brand-main {\n    color: rgb(83, 84, 121);\n    text-align: right;\n    font-size: 14px;\n}\n\n.item-right .text-brand-warn {\n    color: rgb(242, 154, 22);\n    text-align: right;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
